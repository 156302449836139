define("ember-data-table/components/data-table/data-table-content-body", ["exports", "@ember/component", "@ember/template-factory", "@ember/service", "@ember/object", "@glimmer/component"], function (_exports, _component, _templateFactory, _service, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
      isLoading=@data-table.isLoading
      content=@content
      offset=this.offset
      wrappedItems=this.wrappedItems
      enableLineNumbers=@enableLineNumbers
      hasClickRowAction=(and (@onClickRow @rowLink) true)
      onClickRow=@onClickRow
      toggleSelected=this.updateSelection
      selection=@data-table.selection
      enableSelection=@enableSelection
      linkedRoutes=@linkedRoutes
      rowLink=@rowLink
      noDataMessage=@noDataMessage
      customFields=@customFields
      Row=(component "data-table/row"
        data-table=@data-table
        enableLineNumbers=@enableLineNumbers
        enableSelection=@enableSelection
        selection=@data-table.selection
        offset=this.offset
        hasClickRowAction=(and (or @onClickRow @rowLink) true)
        onClickRow=this.onClickRow
        linkedRoutes=@linkedRoutes
        customFields=@customFields
        toggleSelected=this.updateSelection))}}
  
  */
  {
    "id": "oZ6GKhr+",
    "block": "[[[18,10,[[28,[37,1],null,[[\"isLoading\",\"content\",\"offset\",\"wrappedItems\",\"enableLineNumbers\",\"hasClickRowAction\",\"onClickRow\",\"toggleSelected\",\"selection\",\"enableSelection\",\"linkedRoutes\",\"rowLink\",\"noDataMessage\",\"customFields\",\"Row\"],[[30,1,[\"isLoading\"]],[30,2],[30,0,[\"offset\"]],[30,0,[\"wrappedItems\"]],[30,3],[28,[37,2],[[28,[30,4],[[30,5]],null],true],null],[30,4],[30,0,[\"updateSelection\"]],[30,1,[\"selection\"]],[30,6],[30,7],[30,5],[30,8],[30,9],[50,\"data-table/row\",0,null,[[\"data-table\",\"enableLineNumbers\",\"enableSelection\",\"selection\",\"offset\",\"hasClickRowAction\",\"onClickRow\",\"linkedRoutes\",\"customFields\",\"toggleSelected\"],[[30,1],[30,3],[30,6],[30,1,[\"selection\"]],[30,0,[\"offset\"]],[28,[37,2],[[28,[37,4],[[30,4],[30,5]],null],true],null],[30,0,[\"onClickRow\"]],[30,7],[30,9],[30,0,[\"updateSelection\"]]]]]]]]]],[1,\"\\n\"]],[\"@data-table\",\"@content\",\"@enableLineNumbers\",\"@onClickRow\",\"@rowLink\",\"@enableSelection\",\"@linkedRoutes\",\"@noDataMessage\",\"@customFields\",\"&default\"],false,[\"yield\",\"hash\",\"and\",\"component\",\"or\"]]",
    "moduleName": "ember-data-table/components/data-table/data-table-content-body.hbs",
    "isStrictMode": false
  });
  let DataTableContentBodyComponent = _exports.default = (_class = class DataTableContentBodyComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    get offset() {
      var offset = 1; //to avoid having 0. row
      var page = this.args['data-table'].page; // TODO: pass on page directly?
      var size = this.args['data-table'].size; // TODO: pass on size directly?
      if (page && size) {
        offset += page * size;
      }
      return offset;
    }
    get wrappedItems() {
      const selection = this.args['data-table'].selection || []; // TODO: should the data-table ensure this is an array?
      const content = this.args.content;
      return content.map(function (item) {
        return {
          item: item,
          isSelected: selection.includes(item)
        };
      });
    }
    updateSelection(selectedWrapper, event) {
      selectedWrapper.isSelected = event.target.checked;
      this.wrappedItems.forEach(wrapper => {
        if (wrapper.isSelected) {
          this.args['data-table'].addItemToSelection(wrapper.item); // TODO: pass on addItemToSelection directly?
        } else {
          this.arg['data-table'].removeItemFromSelection(wrapper.item); // TODO: pass on removeItemFromSelection directly?
        }
      });
    }
    onClickRow() {
      if (this.args.onClickRow) this.args.onClickRow(...arguments);else if (this.args.rowLink) this.router.transitionTo(this.args.rowLink, arguments[0]);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateSelection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickRow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClickRow"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataTableContentBodyComponent);
});