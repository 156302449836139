define("ember-data-table/components/data-table/th-sortable", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component"], function (_exports, _component, _templateFactory, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
      isSorted=this.isSorted
      inverseSorting=this.inverseSorting
      label=@label
      order=this.order
      isCustom=this.isCustom
      hasCustom=this.hasCustom)}}
  */
  {
    "id": "NH2JdAJI",
    "block": "[[[18,2,[[28,[37,1],null,[[\"isSorted\",\"inverseSorting\",\"label\",\"order\",\"isCustom\",\"hasCustom\"],[[30,0,[\"isSorted\"]],[30,0,[\"inverseSorting\"]],[30,1],[30,0,[\"order\"]],[30,0,[\"isCustom\"]],[30,0,[\"hasCustom\"]]]]]]]],[\"@label\",\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "ember-data-table/components/data-table/th-sortable.hbs",
    "isStrictMode": false
  });
  let ThSortableComponent = _exports.default = (_class = class ThSortableComponent extends _component2.default {
    get dasherizedField() {
      return this.args.field.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    }

    /**
        Inverses the sorting parameter
        E.g. inverseSorting('title') returns '-title'
             inverseSorting('-title') returns 'title'
    */
    _inverseSorting(sorting) {
      if (sorting.substring(0, 1) === '-') {
        return sorting.substring(1);
      } else {
        return '-' + sorting;
      }
    }
    get isSorted() {
      return this.args.currentSorting === this.dasherizedField || this.args.currentSorting === this._inverseSorting(this.dasherizedField);
    }
    get order() {
      if (this.args.currentSorting === this.dasherizedField) {
        return 'asc';
      } else if (this.args.currentSorting === `-${this.dasherizedField}`) {
        return 'desc';
      } else {
        return '';
      }
    }
    get isCustom() {
      return this.args.customHeaders?.split(" ").includes(this.args.field);
    }
    hasCustom() {
      return this.args.customHeaders;
    }

    /**
         Sets the current sorting parameter.
         Note: the current sorting parameter may contain another field than the given field.
         In case the given field is currently sorted ascending, change to descending.
         In case the given field is currently sorted descending, clean the sorting.
         Else, set the sorting to ascending on the given field.
      */
    inverseSorting() {
      if (this.order === 'asc') {
        this.args.updateSort(this._inverseSorting(this.args.currentSorting));
      } else if (this.order === 'desc') {
        this.args.updateSort('');
      } else {
        // if currentSorting is not set to this field
        this.args.updateSort(this.dasherizedField);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "inverseSorting", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "inverseSorting"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ThSortableComponent);
});