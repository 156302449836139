define("ember-data-table/components/data-table/default-data-table-content-body", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
      columns=@data-table.parsedFields
      firstColumn=this.firstColumn
      otherColumns=this.otherColumns
      item=@item
      linkedRoute=@linkedRoute
      customFields=@customfields
      DataCell=(component
        "data-table/data-cell"
        columns=@data-table.parsedFields
        firstColumn=this.firstColumn
        otherColumns=this.otherColumns
        item=@item
        customFields=@customFields
        linkedRoute=@linkedRoute))}}
  
  */
  {
    "id": "wjpWQ2i2",
    "block": "[[[18,6,[[28,[37,1],null,[[\"columns\",\"firstColumn\",\"otherColumns\",\"item\",\"linkedRoute\",\"customFields\",\"DataCell\"],[[30,1,[\"parsedFields\"]],[30,0,[\"firstColumn\"]],[30,0,[\"otherColumns\"]],[30,2],[30,3],[30,4],[50,\"data-table/data-cell\",0,null,[[\"columns\",\"firstColumn\",\"otherColumns\",\"item\",\"customFields\",\"linkedRoute\"],[[30,1,[\"parsedFields\"]],[30,0,[\"firstColumn\"]],[30,0,[\"otherColumns\"]],[30,2],[30,5],[30,3]]]]]]]]],[1,\"\\n\"]],[\"@data-table\",\"@item\",\"@linkedRoute\",\"@customfields\",\"@customFields\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-data-table/components/data-table/default-data-table-content-body.hbs",
    "isStrictMode": false
  });
  class DefaultDataTableContentBodyComponent extends _component2.default {
    get allFields() {
      return this.args['data-table'].parsedFields; // TODO: pass directly?
    }
    get firstColumn() {
      const parsedFields = this.args['data-table'].parsedFields;
      if (parsedFields.length > 0) return parsedFields[0];else return null;
    }
    get otherColumns() {
      const parsedFields = this.args['data-table'].parsedFields;
      if (parsedFields.length > 0) {
        let [, ...fields] = parsedFields;
        return fields;
      } else {
        return [];
      }
    }
  }
  _exports.default = DefaultDataTableContentBodyComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DefaultDataTableContentBodyComponent);
});