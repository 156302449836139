define("ember-data-table/components/data-table/text-search", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/runloop", "@glimmer/component"], function (_exports, _component, _templateFactory, _object, _runloop, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
      submitForm=this.submitForm
      placeholder=this.placeholder
      handleAutoInput=this.handleAutoInput
      handleDirectInput=this.handleDirectInput
      filter=@filter
      auto=@auto)}}
  
  */
  {
    "id": "6Fif3k3Q",
    "block": "[[[18,3,[[28,[37,1],null,[[\"submitForm\",\"placeholder\",\"handleAutoInput\",\"handleDirectInput\",\"filter\",\"auto\"],[[30,0,[\"submitForm\"]],[30,0,[\"placeholder\"]],[30,0,[\"handleAutoInput\"]],[30,0,[\"handleDirectInput\"]],[30,1],[30,2]]]]]],[1,\"\\n\"]],[\"@filter\",\"@auto\",\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "ember-data-table/components/data-table/text-search.hbs",
    "isStrictMode": false
  });
  let TextSearchComponent = _exports.default = (_class = class TextSearchComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "enteredValue", undefined);
      _defineProperty(this, "autoDebouncePid", undefined);
    }
    handleAutoInput(event) {
      this.enteredValue = event.target.value;
      this.autoDebouncePid = (0, _runloop.debounce)(this, this.submitCurrent, this.args.wait);
    }
    submitCurrent() {
      if (!this.isDestroying && !this.isDestroyed) {
        this.args.updateFilter(this.enteredValue);
        this.autoDebouncePid = undefined;
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      (0, _runloop.cancel)(this.autoDebouncePid);
    }
    handleDirectInput(event) {
      this.enteredValue = event.target.value;
    }
    submitForm(event) {
      event.preventDefault();
      this.submitCurrent();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleAutoInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleAutoInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDirectInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDirectInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitForm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submitForm"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TextSearchComponent);
});