define("ember-data-table/components/data-table/data-table-menu-selected", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component"], function (_exports, _component, _templateFactory, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
      selectionIsEmpty=@data-table.selectionIsEmpty
      selectionCount=this.selectionCount
      clearSelection=this.clearSelection
      selection=@data-table.selection
      data-table=@data-table)}}
  
  {{!-- TODO: must we pass the data table itself?  It is shared with the consumers. --}}
  */
  {
    "id": "9OeGnPs1",
    "block": "[[[18,2,[[28,[37,1],null,[[\"selectionIsEmpty\",\"selectionCount\",\"clearSelection\",\"selection\",\"data-table\"],[[30,1,[\"selectionIsEmpty\"]],[30,0,[\"selectionCount\"]],[30,0,[\"clearSelection\"]],[30,1,[\"selection\"]],[30,1]]]]]],[1,\"\\n\\n\"]],[\"@data-table\",\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "ember-data-table/components/data-table/data-table-menu-selected.hbs",
    "isStrictMode": false
  });
  let DataTableMenuSelectedComponent = _exports.default = (_class = class DataTableMenuSelectedComponent extends _component2.default {
    constructor() {
      super(...arguments);
      this.args['data-table'].enableSelection = true; // TODO: is this the best way to handle such case?
    }
    get selectionCount() {
      return this.args['data-table'].selection.length;
    }
    clearSelection() {
      this.args['data-table'].clearSelection();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "clearSelection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearSelection"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataTableMenuSelectedComponent);
});