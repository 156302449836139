define("ember-data-table/components/data-table", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/tracking", "@glimmer/component", "@ember/utils"], function (_exports, _component, _templateFactory, _object, _tracking, _component2, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
    (hash
      Search=(component "data-table/text-search"
         filter=@filter
         auto=this.autoSearch
         updateFilter=this.updateFilter
         wait=this.searchDebounceTime
         placeholder=this.searchPlaceholder)
      Content=(component "data-table/data-table-content"
         content=@content
         noDataMessage=this.noDataMessage
         enableSelection=this.enableSelection
         enableLineNumbers=@lineNumbers
         onClickRow=@onClickRow
         updateSort=this.updateSort
         customHeaders=@customHeaders
         customFields=@customFields
         links=@links
         rowLink=@rowLink
         data-table=this)
      Pagination=(component "data-table/number-pagination"
         page=@page
         size=@size
         itemsOnCurrentPage=@content.length
         sizeOptions=this.sizeOptions
         total=@content.meta.count
         links=@content.meta.pagination
         updatePage=@updatePage
         updateSize=this.updatePageSize)
      Menu=(component "data-table/data-table-menu"
         data-table=this)
      content=@content
      enableSearch=this.enableSearch
      dataTable=this)}}
  
  */
  {
    "id": "UCZObbwf",
    "block": "[[[18,12,[[28,[37,1],null,[[\"Search\",\"Content\",\"Pagination\",\"Menu\",\"content\",\"enableSearch\",\"dataTable\"],[[50,\"data-table/text-search\",0,null,[[\"filter\",\"auto\",\"updateFilter\",\"wait\",\"placeholder\"],[[30,1],[30,0,[\"autoSearch\"]],[30,0,[\"updateFilter\"]],[30,0,[\"searchDebounceTime\"]],[30,0,[\"searchPlaceholder\"]]]]],[50,\"data-table/data-table-content\",0,null,[[\"content\",\"noDataMessage\",\"enableSelection\",\"enableLineNumbers\",\"onClickRow\",\"updateSort\",\"customHeaders\",\"customFields\",\"links\",\"rowLink\",\"data-table\"],[[30,2],[30,0,[\"noDataMessage\"]],[30,0,[\"enableSelection\"]],[30,3],[30,4],[30,0,[\"updateSort\"]],[30,5],[30,6],[30,7],[30,8],[30,0]]]],[50,\"data-table/number-pagination\",0,null,[[\"page\",\"size\",\"itemsOnCurrentPage\",\"sizeOptions\",\"total\",\"links\",\"updatePage\",\"updateSize\"],[[30,9],[30,10],[30,2,[\"length\"]],[30,0,[\"sizeOptions\"]],[30,2,[\"meta\",\"count\"]],[30,2,[\"meta\",\"pagination\"]],[30,11],[30,0,[\"updatePageSize\"]]]]],[50,\"data-table/data-table-menu\",0,null,[[\"data-table\"],[[30,0]]]],[30,2],[30,0,[\"enableSearch\"]],[30,0]]]]]],[1,\"\\n\"]],[\"@filter\",\"@content\",\"@lineNumbers\",\"@onClickRow\",\"@customHeaders\",\"@customFields\",\"@links\",\"@rowLink\",\"@page\",\"@size\",\"@updatePage\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-data-table/components/data-table.hbs",
    "isStrictMode": false
  });
  let DataTable = _exports.default = (_class = class DataTable extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_selection", _descriptor, this);
      _initializerDefineProperty(this, "_enableSelection", _descriptor2, this);
      _defineProperty(this, "_size", undefined);
      _initializerDefineProperty(this, "hasMenu", _descriptor3, this);
    }
    get selection() {
      if (this._selection === undefined && this.args.selection === undefined) return [];else if (this._selection !== undefined) return this._selection;else return this.args.selection;
    }
    set selection(newSelection) {
      this._selection = newSelection; // also trigers dependent properties
    }
    get noDataMessage() {
      return this.args.noDataMessage === undefined ? 'No data' : this.args.noDataMessage;
    }
    get isLoading() {
      return this.args.isLoading;
    }
    get searchDebounceTime() {
      return this.args.searchDebounceTime === undefined ? 2000 : this.args.searchDebounceTime;
    }
    get enableSelection() {
      return this._enableSelection === undefined ? this.args.hasMenu : this._enableSelection;
    }
    set enableSelection(value) {
      this._enableSelection = value;
    }
    get selectionIsEmpty() {
      return this.selection.length === 0;
    }
    get enableSizes() {
      return this.args.enableSizes === undefined ? true : this.args.enableSizes;
    }
    get sort() {
      return this.args.sort;
    }
    get page() {
      return this.args.page;
    }
    get size() {
      if (this._size === undefined && this.args.size) return this.args.size;else if (this._size) return this._size;else return 5;
    }
    set size(newSize) {
      this._size = newSize;
    }
    get sizeOptions() {
      if (!this.enableSizes) {
        return null;
      } else {
        const sizeOptions = this.args.sizes || [5, 10, 25, 50, 100];
        if (!sizeOptions.includes(this.size)) {
          sizeOptions.push(this.size);
        }
        sizeOptions.sort((a, b) => a - b);
        return sizeOptions;
      }
    }
    // old comment: set from inner component, migth fail with nested if

    get enableSearch() {
      return this.args.filter !== undefined;
    }
    get autoSearch() {
      return this.args.autoSearch === undefined ? true : this.args.autoSearch;
    }
    get parsedFields() {
      const fields = this.args.fields;
      if ((0, _utils.typeOf)(fields) === 'string') {
        return fields.split(' ');
      } else {
        return fields || [];
      }
    }
    get searchPlaceholder() {
      return this.args.searchPlaceholder === undefined ? 'Search input' : this.args.searchPlaceholder;
    }
    updatePageSize(size) {
      this.args.updatePage(0);
      this.args.updatePageSize(size);
    }
    updateFilter(filter) {
      this.args.updatePage(0);
      this.args.updateFilter(filter);
    }
    updateSort(sort) {
      this.args.updatePage(0);
      this.args.updateSort(sort);
    }
    addItemToSelection(item) {
      this.selection = [item, ...this.selection];
    }
    removeItemFromSelection(item) {
      this.selection = this.selection.filter(x => x !== item);
    }
    clearSelection() {
      this.selection = [];
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_selection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_enableSelection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasMenu", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updatePageSize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updatePageSize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSort", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSort"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataTable);
});